import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: theme.colors.gray[2],
    width: "100%",
    height: "fit-content",
  },
  labelFormLogin: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  paddingVerticalTitle: {
    marginBottom: "30px",
    paddingTop: "30px",
  },
  btnCreateForgot: {
    paddingTop: "15px",
    cursor: "pointer",
  },
}));

export default useStyles;
