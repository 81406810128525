import { useApiContext } from "@/setup/flexbiz-api-provider";
import { setToStorage } from "@/utils/localStorage";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";

const checkError = (error, form) => {
  error.indexOf("Tài khoản") > -1
    ? form.setFieldError("email", error)
    : form.setFieldError("password", error);
};

const ButtonSubmitLogin = ({ context, valuePassword, valueEmail, form }) => {
  const login = async () => {
    try {
      form.validate();

      if (form.errors.email || form.errors.password) {
        return;
      }
      const userInfo = await context.apis.asyncLoginAPI(
        valueEmail,
        valuePassword
      );

      setToStorage("updateInfo", true);
      setToStorage("token", userInfo?.token);
      window.location.href = "/";
    } catch (error) {
      checkError(error.message || "Có lõi vui lòng thử lại", form);
    }
  };
  return (
    <Button
      type="submit"
      onClick={login}
      sx={{ marginTop: "15px" }}
      fullWidth
      color="orange"
    >
      Đăng nhập
    </Button>
  );
};

export function InputLogin() {
  const [valuePassword, setValuePassword] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const context = useApiContext();
  const form = useForm({
    initialErrors: { password: null },
    initialValues: { password: "", email: "" },
    validate: {
      email: (value) => {
        return /^\S+@\S+$/.test(value) ? "Email không hợp lệ" : null;
      },
      password: (value) =>
        /(?=.*[a-z])(?=.*\d)(.){6,}$/.test(value)
          ? "Mật khẩu yêu cầu 6 ký tự bao gồm chữ và số"
          : null,
    },
  });

  return (
    <form onSubmit={form.onSubmit(console.log)}>
      <TextInput
        {...form.getInputProps("email")}
        mt="sm"
        label="Email"
        placeholder="Email"
        value={valueEmail}
        onChange={(e) => setValueEmail(e.currentTarget.value)}
      />
      <PasswordInput
        {...form.getInputProps("password")}
        label="Mật khẩu"
        placeholder="Mật khẩu của bạn"
        error={form.errors.password}
        value={valuePassword}
        onChange={(e) => setValuePassword(e.currentTarget.value)}
      />
      <ButtonSubmitLogin
        valueEmail={valueEmail}
        valuePassword={valuePassword}
        context={context}
        mt="sm"
        form={form}
      />
    </form>
  );
}
