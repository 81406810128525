import { Center, Text, UnstyledButton } from "@mantine/core";
import Link from "next/link";
import React from "react";

function ButtonLogin(props) {
  const { title, href } = props;

  return (
    <Center sx={{ paddingTop: "10px" }}>
      <UnstyledButton {...props}>
        <Link scroll={false} href={href}>
          <Text size="sm" underline>
            {title}
          </Text>
        </Link>
      </UnstyledButton>
    </Center>
  );
}

export default ButtonLogin;
